import React from 'react'
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness'
import { ThemeProvider } from '@aws-amplify/ui-react'
import Spinner from './Spinner'

export function LivenessQuickStartReact() {
	const [loading, setLoading] = React.useState(true)
	const [platform, setPlatform] = React.useState('')
	const [laravelBaseUrl, setLaravelBaseUrl] = React.useState('')
	const [createLivenessApiData, setCreateLivenessApiData] = React.useState(null)
	const [sessionId, setSessionId] = React.useState(null)

	React.useEffect(() => {
		const params = new URLSearchParams(window.location.search)

		console.log(params.get('platform'))

		if (params) {
			setPlatform(params.get('platform'))
			setLaravelBaseUrl(params.get('baseUrl'))
		}

		const fetchCreateLiveness = async () => {
			const response = await fetch('https://liveness-api.monfibank.com/api/get-session', {
				headers: {
				//'api-key' : '$2y$10$ITCGB3rZsvclvU9f7WhTHO6JC4wQxx4r8mDL6m3/0gaOLp3Fg8SiS'
			}})

			var dataa = await response.json()
			setSessionId(dataa.data.sessionId)

			console.log(dataa.data.sessionId)
				
			await new Promise((r) => setTimeout(r, 2000))
			const mockResponse = { sessionId: dataa.data.sessionId }
			const data = mockResponse

			setCreateLivenessApiData(data)
			setLoading(false)
		}

		fetchCreateLiveness()
	}, [])

	const arrayBufferToBase64 = (buffer) => {
		let binary = ''
		const bytes = new Uint8Array(buffer)
		const len = bytes.byteLength

		for (let i = 0; i < len; i++) {
			binary += String.fromCharCode(bytes[i])
		}

		return window.btoa(binary)
	}

	async function uploadSelfie(base64String) {
		console.log('before Upload Selfie API Call')
                                        try {
                                                const response = await fetch('http://47.250.135.35/api/user/upload-selfie', {
                                                        headers: {
                                                                "api-key" : "$2y$10$ITCGB3rZsvclvU9f7WhTHO6JC4wQxx4r8mDL6m3/0gaOLp3Fg8SiS",
                                                                "Content-Type": "application/json"
                                                        },
                                                        method: 'POST',
                                                        body: JSON.stringify({ 'selfie_photo': base64String })
                                                })

                                                console.log('after hitting the API')
                                                const uploadSelfieResponse = await response.json()
                                                console.log(uploadSelfieResponse)

                                                if (uploadSelfieResponse.success) {
                                                        return uploadSelfieResponse.selfie_url
                                                }

						return false
                                        } catch (error) {
                                                console.error('Error occurred: ')
                                                console.error(error)
						return false
                                        }
	}

	const handleAnalysisComplete = async () => {
		// console.log('Before API Call')

		const response = await fetch('https://liveness-api.monfibank.com/api/get-result/?sessionId=' + sessionId)
		const res = await response.json()

		console.log('After API Call')

		/*
		* Note: The isLive flag is not returned from the GetFaceLivenessSession API
		* This should be returned from your backend based on the score that you
		* get in response. Based on the return value of your API you can determine what to render next.
		* Any next steps from an authorization perspective should happen in your backend and you should not rely
		* on this value for any auth related decisions.
		*/

		console.log(res)

		var console_result = ""

		// console.log(res.data.ReferenceImage.Bytes.data)

		if (res.data !== undefined) {
			var base64String = arrayBufferToBase64(res.data.ReferenceImage.Bytes.data)

			if (res.data.Confidence > 75) {
				if (platform == 'web') {
					window.parent.postMessage({ liveness_status: true, selfie_image: base64String }, laravelBaseUrl)
				} else if (platform == 'ios') {
					window.message.postMessage(JSON.stringify({ liveness_status: true, selfie_image: base64String }), '*')
					/*
					const selfie_url = uploadSelfie(base64String)
						if (selfie_url) {
							base64String = selfie_url
						}
					*/
				}

				console_result = 'User is live | ' + base64String
			} else {
				if (platform == 'web') {
					window.parent.postMessage({ liveness_status: false }, laravelBaseUrl)
				} else if (platform == 'ios') {
					window.message.postMessage(JSON.stringify({ liveness_status: false, error: 'User is not live' }), '*')
				}

				console_result = 'User is not live'
			}
		} else {
			if (platform == 'web') {
				window.parent.postMessage({ liveness_status: false }, laravelBaseUrl)
			} else if (platform == 'ios') {
				window.message.postMessage(JSON.stringify({ liveness_status: false, error: 'User is not live' }), '*')
			}

			console_result = 'User is not live'
		}

		if (platform == 'android') {
			console.log(console_result)
		}
	}

	return (
		<ThemeProvider>
			{loading ? (
			<Spinner />
			) : (
				<FaceLivenessDetector
					sessionId={createLivenessApiData.sessionId}
					region="us-east-1"
					onAnalysisComplete={handleAnalysisComplete}
					onError={(error) => {
						if (platform == 'web') {
							console.log('React Application logging -> Platform: ' + platform + ' | Base URL: ' + laravelBaseUrl)
							window.parent.postMessage({ liveness_status: false }, laravelBaseUrl)
						} else if (platform == 'ios') {
							window.message.postMessage(JSON.stringify({ liveness_status: false, error }), '*')
						} else {
							console.error(error)
						}
					}}
				/>
			)}
		</ThemeProvider>
	)
}
